export const header ={
    news:'Blogs',
    about_products:'Products',
    join_us:'Join Us',
    about_us:'About',
    contact_us:'Contact',
    chinese:'中文',
    japanese:'日本語',
    english:'English'
}

export const home ={
    lastest_news:'NEWS',
    product_list:'PRODUCT LIST',
    buy:'BUY',
    cantbuy:'SOLD OUT',
    detail:'DETAIL'
}

export const products ={
    by_from:'By Series',
    by_type:'By Type',
    all:'All',
    series_name: 'Series',
    type: 'Specifications',
    scale: 'Scale',
    material: 'Product Material',
    sales_date: 'Sales Date',
    content: 'Description',
}

export const footer ={
    followus:'Follow us',
    weibo:'Weibo',
    twitter:'X',
    bilibili:'Bilibili',
    redbook:'小红书',
    companyinfo:'Company Information',
    about_us:'About',
    product_list:'Product List',
    aboutcopyright:'关于著作权和商标',
    contact_us:'Contact',
    aboutaftersell:'售后相关',
    businesswork:'Business Cooperation',
    join_us:'Join Us',
}