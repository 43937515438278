export const header ={
    news:'星环近况',
    about_products:'产品相关',
    join_us:'加入我们',
    about_us:'关于星环',
    contact_us:'联系我们',
    chinese:'中文',
    japanese:'日本語',
    english:'English'
}

export const home ={
    lastest_news:'最新新闻',
    product_list:'产品列表',
    buy:'购买',
    cantbuy:'缺货中',
    detail:'详情'
}

export const products ={
    by_from:'按作品',
    by_type:'按类型',
    all:'全部',
    series_name:'作品名',
    type:'类型',
    scale:'比例',
    material:'材料',
    release_date:'发布日期',
    sales_date:'发售日期',
    content:'简介'
}

export const footer ={
    followus:'关注我们',
    weibo:'新浪微博',
    twitter:'X',
    bilibili:'Bilibili',
    redbook:'小红书',
    companyinfo:'公司信息',
    about_us:'关于星环',
    product_list:'产品列表',
    aboutcopyright:'关于著作权和商标',
    contact_us:'联系我们',
    aboutaftersell:'售后相关',
    businesswork:'商务合作',
    join_us:'加入我们',
}