
import Vue from 'vue'
import Vuex from 'vuex';
import axios from 'axios';
import {
    mapState
} from 'vuex'

export default Vue.extend({
    name: 'ProductDetail',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            imageList: [],
            contentImageList:[],
            detailDirectory: '',
            bannerHeight:120,
            perPage:5,
        };
    },
    components: {

    },
    computed: {
        ...mapState(['productList', 'currentLanguage']),
        currentLang() {
            return this.currentLanguage;
        },
        currentProduct() {
            let currentProduct = this.productList.filter(item => item.id === this.id)[0];
            return currentProduct;
        }
    },
    methods: {
        setSize: function () {
            // 通过屏幕宽度(图片宽度)计算高度
            if(this.screenWidth<1200){
                this.perPage = 3;
            }
            if(this.screenWidth<600){
                this.perPage = 2;
            }
            //this.bannerHeight = 192 / 351 * this.screenWidth;
        },
        proxyImage: function (e) {
            let currentEle = e.target;
            if (currentEle.tagName.toUpperCase() === 'IMG') {
                window.open(e.target.getAttribute('src'), '_blank');
            }
        },
        loadImages() {
            this.imageList = []; 
            // 清空数组，以防止重复添加
            if( this.currentProduct.imageCount === 0){
                return false
            }
            // 生成图片路径并添加到数组中
            for (let i = 1; i <= this.currentProduct.imageCount; i++) {
                // 使用字符串插值构建图片路径
                const imagePath = `/img/products-images/${this.id}/images/` + ('00' + i).slice(-3) + '.jpg';
                this.imageList.push(imagePath);
            }
        },
        loadContentImages() {
            this.contentImageList = []; 
            // 清空数组，以防止重复添加
            if( this.currentProduct.contentCount === 0){
                return false
            }
            // 生成图片路径并添加到数组中
            for (let i = 1; i <= this.currentProduct.contentCount; i++) {
                // 使用字符串插值构建图片路径
                const imagePath = `/img/products-images/${this.id}/content-images/` + ('00' + i).slice(-3) + '.jpg';
                this.contentImageList.push(imagePath);
            }
        },
        getItemDetailPicLink(id){
            const imgLink = '/img/products-images/'+ id +'/detail_pic.jpg';
            return imgLink;
        },
    },
    mounted() {
        this.$store.dispatch('getProductList');
        this.screenWidth = window.innerWidth;
        this.setSize();
        window.onresize = () => {
            this.screenWidth = window.innerWidth;
            this.setSize();
        };
    },
    watch: {
        currentProduct: {
            handler: function(newValue, oldValue) {
            // 检查currentProduct是否有内容
            if (newValue && !!newValue.contentCount) {
                this.loadContentImages();
            }
            if (newValue && !!newValue.imageCount) {
                this.loadImages();
            }
            },
            immediate: true,
            deep: true // 深度监控currentProduct对象及其子属性的变化
        }
    }
})
