
import Vue from 'vue'
import {
    mapState
} from 'vuex'

export default Vue.extend({
    name: 'FooterView',
    data() {
        return {
            screenWidth: null,
            dropdownShow: [{
                show: false
            }, {
                show: false
            }, {
                show: false
            }],
        };
    },
    components: {},
    methods: {
        clickToggle(value) {
            this.dropdownShow[value].show = !this.dropdownShow[value].show;
        },
    },
    computed: {
        ...mapState(['currentLanguage']),
        currentLang() {
            return this.currentLanguage;
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth;
        window.addEventListener('resize', () => {
            this.screenWidth = document.body.clientWidth;
        });
    },
})
