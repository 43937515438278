
import Vue from 'vue'
import Vuex from 'vuex';
import axios from 'axios';
import {
    mapState
} from 'vuex'

export default Vue.extend({
    name: 'ProductView',
    data() {
        return {
            nowFilterType: '',
            nowFilterValue: '',
            filterOpen: false,
        };
    },
    mounted() {
        this.$store.dispatch('getProductList');
        this.nowFilterType = '';
        this.nowFilterValue = '';
    },
    components: {},
    methods: {
        handleFilterToggle() {
            this.filterOpen = !this.filterOpen
        },
        getItemPicLink(id){
            const imgLink = '/img/products-images/'+ id +'/list_pic.png';
            return imgLink;
        },
        handleFilter(type, value) {
            this.nowFilterType = type;
            this.nowFilterValue = value;
            this.filterOpen = false;
        },
        nowSeriesFilter(arrObj) {
            const map = new Map()
            const NewArrObject = arrObj.map(item => {
                return Object.assign({}, {
                    'zhFrom': item.zhFrom,
                    'enFrom': item.enFrom,
                    'jaFrom': item.jaFrom
                })
            })
            const newArr = NewArrObject.filter(v => !map.has(v.zhFrom) && map.set(v.zhFrom, 1));
            return newArr;
        },
        nowTypeFilter(arrObj) {
            const map = new Map()
            const NewArrObject = arrObj.map(item => {
                return Object.assign({}, {
                    'zhProductType': item.zhProductType,
                    'enProductType': item.enProductType,
                    'jaProductType': item.jaProductType
                })
            })
            const newArr = NewArrObject.filter(v => !map.has(v.zhProductType) && map.set(v.zhProductType, 1));
            return newArr;
        },
    },
    computed: {
        ...mapState(['productList', 'currentLanguage']),
        currentProductsList() {
            if (this.productList) {
                if (this.nowFilterType && this.nowFilterValue) {
                    if (this.nowFilterType === 'Type') {
                        let currentProductsList = this.productList.filter(item => item.enProductType === this.nowFilterValue);
                        return currentProductsList;
                    } else if (this.nowFilterType === 'Series') {
                        let currentProductsList = this.productList.filter(item => item.enFrom === this.nowFilterValue);
                        return currentProductsList;
                    } else {
                        let currentProductsList = this.productList;
                        return currentProductsList;
                    }
                } else {
                    let currentProductsList = this.productList;
                    return currentProductsList;
                }

            } else {
                return false;
            }
        },
        currentSeriesFilterList() {
            return this.nowSeriesFilter(this.productList);
        },
        currentTypeFilterList() {
            return this.nowTypeFilter(this.productList);
        },
        currentLang() {
            return this.currentLanguage;
        }
    }
})
