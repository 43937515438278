import Vue from 'vue'
import Vuex from 'vuex'
import {reqAllNews , reqAllProducts} from '@/api'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    newList:[],
    productList:[],
    currentLanguage:'zh',
  },
  getters: {
  },
  mutations: {
    GETNEWLIST(state, newList) {
      state.newList = newList
    },
    GETPRODUCTLIST(state, productList) {
      state.productList = productList
    },
    GETNOWLANGUAGE(state, nowlanguage) {
      state.currentLanguage = nowlanguage
    }
  },
  actions: {
    async getNewList({ commit }) {
      let result = await reqAllNews()
      if (result.status === 200) {
        commit('GETNEWLIST', result.data)
      }
    },
    async getProductList({ commit }) {
      let result = await reqAllProducts()
      if (result.status === 200) {
        commit('GETPRODUCTLIST', result.data)
      }
    },
  },
  modules: {
  }
})
