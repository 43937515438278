import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import './registerServiceWorker'
import './style/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
import '@/mock/mockServe.js'
import axios from 'axios'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import HeaderView from './views/header/index.vue'
import FooterView from './views/footer/index.vue'



Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh', // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    ja: require('./language/ja'), // 日语
    zh: require('./language/zh'), // 中文
    en: require('./language/en') // 英文
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

new Vue({
  i18n,
  router,
  store,
  render: h => h(HeaderView)
}).$mount('#header')

new Vue({
  i18n,
  router,
  store,
  render: h => h(FooterView)
}).$mount('#footer')

