export const header ={
    news:'ニュース',
    about_products:'製品一覧',
    join_us:'採用情報',
    about_us:'星環情報',
    contact_us:'コンタクト',
    chinese:'中文',
    japanese:'日本語',
    english:'English'
}

export const home ={
    lastest_news:'最新情報',
    product_list:'製品一覧',
    buy:'購入',
    cantbuy:'在庫切れ',
    detail:'詳細'
}

export const products ={
    by_from:'作品別',
    by_type:'タイプ別',
    all:'全部',
    series_name:'作品',
    type:'製品仕様',
    scale:'スケール',
    material:'素材',
    sales_date:'発売時期',
    content:'商品説明',
}

export const footer ={
    followus:'SNS',
    weibo:'ウェイボー',
    twitter:'X',
    bilibili:'Bilibili',
    redbook:'小红书',
    companyinfo:'会社情報',
    about_us:'星環情報',
    product_list:'製品一覧',
    aboutcopyright:'关于著作权和商标',
    contact_us:'コンタクト',
    aboutaftersell:'售后相关',
    businesswork:'ビジネス協力',
    join_us:'採用情報',
}