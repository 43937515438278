import mockRequests from './mockAjax'

export const reqAllNews = () =>
  mockRequests({
    url: '/news',
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })


  export const reqAllProducts = () =>
  mockRequests({
    url: '/products',
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })
