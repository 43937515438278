
import Vue from 'vue'
import {
    mapState,
    mapMutations
} from 'vuex'
import _ from 'lodash';

export default Vue.extend({
    name: 'HeaderView',
    data() {
        return {
            scrollTop: 0,
            scrollStatus: false,
            screenWidth: '',
            langSelectPopup: false,
            language: 'zh',
            mobilePopupShow: false,
        };
    },
    components: {},
    methods: {
        handlePopupToggle() {
            this.mobilePopupShow = !this.mobilePopupShow
        },
        handleScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            this.scrollTop = scrollTop;

            if (scrollTop > 0) {
                this.scrollStatus = true;
            } else {
                this.scrollStatus = false;
            }
        },
        handleLangSelectPopup() {
            this.langSelectPopup = !this.langSelectPopup;
        },
        handleLangSelectPopupClose() {
            this.langSelectPopup = false;
        },
        selectLanguage(any) {
            // 根据监听改变获取到的值去数组里找到对应的值
            localStorage.setItem('lang', any);
            this.language = any;
            this.$store.commit('GETNOWLANGUAGE', this.language);
            this.$i18n.locale = any; //切换字典文件
            window.location.reload();
        },
        resize() {
            return _.debounce(() => {
                this.screenWidth = window.innerWidth;
                const isLarge = this.screenWidth >= 1200;
                this.mobilePopupShow = isLarge;
            }, 100);
        },
    },
    mounted() {
        if (window.innerWidth > 1200) {
            this.mobilePopupShow = true;
        } else {
            this.mobilePopupShow = false;
        }
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.resize());
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
        if (localStorage.getItem('lang')) {
            this.language = localStorage.getItem('lang');
            this.$store.commit('GETNOWLANGUAGE', this.language);
        } else {
            localStorage.setItem('lang', this.language);
            this.selectLanguage(this.language);
            this.$store.commit('GETNOWLANGUAGE', this.language);
        }
    },
    
    watch: {
        $route(to) {     
            if (to) {
                this.mobilePopupShow = false;
            }
        },
    },
    computed: {
        ...mapState(['currentLanguage']),
    }
})
