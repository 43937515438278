
import Vue from 'vue'
import Vuex from 'vuex';
import axios from 'axios';
import {
    mapState
} from 'vuex'

export default Vue.extend({
    name: 'HomeView',
    data() {
        return {
            carouselImageList: [{
                    Id: '1',
                    ImageUrl: require('@/assets/images/carousel-images/1.jpg'),
                    Url: 'https://item.taobao.com/item.htm?id=721810930171&scene=taobao_shop&spm=a1z10.1-c.w5003-25094642583.2.43c72233xkKPp7'
                },
                {
                    Id: '2',
                    ImageUrl: require('@/assets/images/carousel-images/3.png'),
                    Url: 'https://xhzgmodel.taobao.com/category-1734859915.htm?spm=a1z10.1-c.w5003-25094642583.1.43c72233xkKPp7&search=y&catName=EVA&scene=taobao_shop#bd'
                },
                {
                    Id: '3',
                    ImageUrl: require('@/assets/images/carousel-images/2.png'),
                    Url: 'https://item.taobao.com/item.htm?spm=a1z10.3-c.w4002-17215584938.17.6935445dmsZJTv&id=693231513624'
                }
            ],
            bannerHeight: 100, //图片父容器的高度
            screenWidth: 0, //屏幕的宽度
        }
    },
    mounted() {
        this.$store.dispatch('getNewList');
        this.$store.dispatch('getProductList');

        this.screenWidth = window.innerWidth;
        this.setSize();
        window.onresize = () => {
            this.screenWidth = window.innerWidth;
            this.setSize();
        };
    },
    methods: {
        getItemPicLink(id){
            const imgLink = '/img/products-images/'+ id +'/list_pic.png';
            return imgLink;
        },
        goToNewsLink(link) {
            window.open(link);
        },
        setSize: function () {
            // 通过屏幕宽度(图片宽度)计算高度
            this.bannerHeight = 1080 / 1920 * this.screenWidth;
        },
    },
    components: {},
    computed: {
        ...mapState(['newList', 'productList', 'currentLanguage']),
        currentNewsList() {
            if (this.newList) {
                // let currentNewsList = this.newList.filter(item => item.language === this.currentLanguage);
                let currentNewsList = this.newList;
                return currentNewsList;
            } else {
                return false;
            }

        },
        currentProductsList() {
            if (this.productList) {
                let currentProductsList = this.productList;
                return currentProductsList;
            } else {
                return false;
            }

        },
        currentLang() {
            return this.currentLanguage;
        }
    }
})
